.lorryPage_Container {
  height: 93vh;
  width: 100%;
  padding: 0.6vw;
}
.SearchInpLorry_dataTable {
  width: 98%;
  /* height: 1vh; */
  height: 2.5vw;
  outline: none;
  border: none;
  padding: 0 1vw;
  background-color: var(--thirdBackground);
  border-radius: 2vw;
}
.SearchInpLorry_dataTable::placeholder {
  font-size: 1vw;
  font-weight: 400;
}
.SearchDiv_lorryDiv {
  height: 10%;
  display: none;

  justify-content: center;

  width: 100%;
}
.viewLorry_Application {
  cursor: pointer;
  color: var(--primaryColor);
}
.Lorry_Heading_Table {
  color: var(--commonWhite);
}
.TableDiv_lorryDiv {
  height: 100%;
  width: 100%;
  font-size: 1vw;
  overflow: auto;
  position: relative;
}
.TableDiv_lorryDiv_heading {
  height: 2vw;
  width: 100%;
  background-color: var(--secondaryColor);
  position: sticky;
  font-weight: 400;
  top: 0;
}
.tbody_truckLorryData_row {
  overflow: auto;
  font-weight: 500;
}
.table_container_lorry {
  overflow: auto;
}

.evenData_Table {
  background-color: var(--thirdBackground);
}
.oddData_Table {
  background-color: var(--primary2ndColor);
}
.truckLorryData_row {
  width: 100%;
  height: 2.5vw;
  color: var(--primaryTextColor);
}

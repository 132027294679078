* {
  margin: 0;
  padding: 0;
}
.ChangeRouteSelectDataPopUp_main_container {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 2%;
  background-color: white;
  overflow-y: scroll;
}
.ChangeRouteSelectDataPopUp_main_container::-webkit-scrollbar {
  width: 0.5rem;
  background-color: white;
  border-radius: 1rem;
}

.ChangeRouteSelectDataPopUp_main_container::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor);
  border-radius: 1rem;
}
.changeRouteSelectDataPopUp_hideBtn_box {
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1rem;
  padding: 0 1rem;
}
.changeRouteSelectDataPopUp_select_truck_Size {
  margin-top: 2%;
}

.changeRouteSelectDataPopUp_hideBtn_box h2 {
  color: var(--primaryColor);
  cursor: pointer;
  font-size: 2vw;
  font-weight: bold;
  position: fixed;
}
.changeRouteSelectDataPopUp_select_box {
  width: 100%;
  height: 7%;
  justify-content: space-between;
  display: flex;
  margin-top: 2%;
}
.changeRouteSelectDataPopUp_select_selectState {
  width: 48%;
  height: 6vh;
}
.changeRouteSelectDataPopUp_select_box select {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 0.2rem solid var(--primaryColor);
}
.changeRouteSelectDataPopUp_select_selectDistrict {
  width: 48%;
  height: auto;
}
.changeRouteSelectDataPopUp_select_selectDistrict select {
  width: 100%;
}

.changeRouteSelectDataPopUp_checkbox {
  width: 20%;
}

.changeRouteSelectDataPopUp_radio_main_box {
  display: flex;
  width: 100%;
}
.changeRouteSelectDataPopUp_radio {
  width: 20%;
  font-size: 1.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeRouteSelectDataPopUp_radio img {
  height: 60%;
  width: 25%;
  margin-left: 2%;
  object-fit: contain;
}
.changeRouteSelectDataPopUp_radio input {
  width: 5%;
}

.ChangeRouteSelectDataPopUp_main_container button {
  background-color: var(--primaryColor);
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 20%;
  margin: 0.5rem 0;
  position: relative;
  left: 75%;
}

.changeRouteSelectDataPopUp_checkbox_add_newbox_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 5vh;
  color: white;
  font-size: 1.2vw;
  margin-top: 3%;
  font-weight: bold;
}
.changeRouteSelectDataPopUp_checkbox_add_newbox_btn span {
  font-size: 1.9vw;
  color: var(--primaryColor);
  font-weight: bold;
  cursor: pointer;
}


.selected-checkbox,
.selected-radio {
    background-color: var(--primaryColor); 
    border-radius: 4px;
}

.checkbox-label,
.radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-image,
.changeRouteSelectDataPopUp_radio_img {
    margin-left: 1rem;
    width: 3rem; 
}
.changeRouteSelectDataPopUp_gst_input{
  width: 30%;
  padding: 1% 2%;
  height: 6vh;
  margin: 0;
  margin-top: 2%;
}
.statDetails {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    justify-content: space-between;
}

.detailSearch {
    width: 100%;
    display: flex;
    column-gap: .5rem;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

}

.loadCounts {
    width: 16%;
    display: flex;
    background-color: var(--primary2ndColor);
    padding: .2rem;
    text-align: center;
    flex-direction: column;
    color: var(--primaryTextColor);
    height: 100%;
    align-items: center;
    justify-content: center;
}

.loadCounts>h3 {
    color: var(--secondaryColor);
}

.loadCounts>h3>span {
    color: var(--primaryColor);
}

.searchbox {
    width: 100%;
    display: flex;
    height: 100%;
    padding: 1vh 0;
}

.searchbox>input {
    width: 90%;
    background-color: var(--thirdBackground);
    outline: none;
    padding: .9rem .4rem;
    border: none;
    color: var(--primaryTextColor);
    font-size: 1vw;
    margin: 0;
}

.searchbox>input::placeholder {
    font-size: 1vw;
}

.searchbox>button {
    width: 10%;
    border: none;
    outline: none;
    padding: 0.2rem 0.4rem;
    font-size: 1vw;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--primary2ndColor);
    color: var(--primaryTextColor);
    margin: 0;
}

.loadButtons {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
    height: 38%;

}

.loadButtons>div {
    width: 32%;
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    background-color: var(--buttonColor);
    color: var(--primaryTextColor);
    cursor: pointer;
    font-weight: bold;
    height: 100%;
    font-size: 1.1vw;

}
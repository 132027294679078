.LorryPopUp_container {
  height: 10vw;
  width: 30vw;
  background-color: var(--popBg);
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-around;
}
.LorryPopUp_CrossBtn {
  height: 100%;
  font-size: 1vw;
  position: absolute;
  top: 0;
  right: 0;
}
.popUp_btns_lorryTruck {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: start;
  gap: 3vw;

  padding-left: 1.9vw;
}
.popUp_btns_button {
  width: 30% !important;
  padding: 0 0vw;
}
.Cut_Icon_cross_click {
  height: 1.3vw;
  width: 100%;
  align-items: start;
  cursor: pointer;
  position: relative;
  justify-content: end;
}
.popUp_Content_Lorry {
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2vw;
}
.button_division_navigate {
  font-weight: bold;
}

.userDetailsPage {
  width: 100%;
  height: 110vh;
  display: flex;
}

.ProfileSection {
  width: 27%;
  background-color: var(--popBg);
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailSection {
  width: 70%;
  padding: 1vw;
  background-color: var(--thirdBackground);
  display: flex;

  flex-direction: column;
  gap: 0.4vw;
}
.paymentStatus {
  display: flex;
  flex-direction: column;
  gap: 2%;
  justify-content: center;
  align-items: center;
}
.paymentStatus h4 {
  color: var(--primaryColor);
  font-size: 1.1rem;
}

.paymentStatus h6 {
  color: var(--secondaryColor);
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: underline;
}
.popUpBackBtn {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 5%;
}
.popUpBackBtn button {
  width: 40%;
  padding: 2% 4%;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--thirdBackground);
  outline: none;
  background-color: var(--thirdBackground);
  color: var(--secondaryColor);
  cursor: pointer;
}

.popUpBackBtn button:hover {
  border: 1px solid var(--secondaryColor);
}

.UserImage {
  width: 10vw;
  height: 10vw;
  margin: auto;
  border-radius: 50%;
  border: 1px solid var(--primaryColor);
}

.UserImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.ProfileSection > h3 {
  font-size: 1.5vw;
  text-align: center;
  margin: 1vw;
}

.userVarStatus > h4 {
  font-size: 1vw;
}

.detailSectionButtons {
  width: 100%;
  background-color: var(--popBg);
  display: flex;

  justify-content: flex-end;
  gap: 9%;
  padding: 1%;
}

.detailSectionButtons button:hover {
  border: 1px solid var(--secondaryColor);
}

.detailSectionButtons button {
  width: 27%;
  padding: 1% 3%;
  font-size: 1vw;
  font-weight: 600;
  border: 1px solid var(--thirdBackground);
  outline: none;
  background-color: var(--thirdBackground);

  color: var(--secondaryColor);
  cursor: pointer;
}

.typeHeader {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.3vw;
  position: relative;
}

.typeHeader > h4 {
  width: 50%;
  color: var(--primaryTextColor);
  font-size: 1vw;
}

.typeHeader > h4 > span {
  color: var(--primaryColor);
}

.ducumentSection1 {
  border: 1px solid var(--primaryColor);
  width: 100%;
  margin: 1vw 0;
  padding: 1% 2%;
  background-color: var(--popBg);
  height: 23vh;
}

.ducumentSection1 > h4 {
  font-size: 1.2vw;
  color: var(--primaryTextColor);
  margin: 1vw 0;
}

.ducumentSection1_div {
  display: flex;
  width: 90%;
  justify-content: space-between;
  gap: 5%;
}

.ducumentSection1_content {
  display: grid;
  grid-template-columns: 2fr 2fr;
  width: 100%;
  row-gap: 28%;
}

.ducumentSection1_div div h5 {
  color: var(--primaryTextColor);
  font-size: 1vw;
}

.ducumentSection1_div div p {
  color: var(--primaryColor);
  font-size: 1vw;
  font-weight: 600;
}

.ducumentSection1_div button {
  padding: 1% 3%;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--thirdBackground);
  outline: none;
  background-color: var(--thirdBackground);
  color: var(--secondaryColor);
  cursor: pointer;
}

.ducumentSection1_div button:hover {
  border: 1px solid var(--secondaryColor);
}

.ducumentSection {
  width: 100%;
  /* margin: 1vw 0; */
}

.ducumentSection > h4 {
  font-size: 1.2vw;
  color: var(--primaryTextColor);
  margin: 1vw 0;
}

.ducumentSection > h5 {
  font-size: 1vw;
  color: var(--primaryTextColor);
  margin: 1vw 0;
}
.lorryNumberCheck {
  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 12%;
  margin: 0;
  padding: 0;
  font-size: 1.4vw;
  border: none;
}

.LorryDetailsPopUp_main_container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.441);
  /* background-color: var(--popBg); */
}
.LorryDetailsPopUp_container {
  width: 20%;
  background-color: var(--thirdBackground);
  min-height: 40%;
  max-height: 70%;
  border-radius: 1vw;
  padding: 1vw;
  box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.LorryDetailsPopUp_container ul li {
  list-style: none;
  font-size: 1.2vw;
  margin: 1vw 0;
  color: var(--primaryTextColor);
}
.LorryDetailsPopUp_container div {
  display: flex;
  align-items: center;
  justify-content: end;
}
.LorryDetailsPopUp_container div h3 {
  cursor: pointer;
}

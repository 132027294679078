.side-navbar {
    width: 20%;
    height: 93vh;
    position: absolute;
    top: 7vh;
    background-color: var(--thirdBackground);
    text-align: center;
}

.side-navbar>p {
    margin: .4rem;
    color: var(--primaryTextColor);
}

.profileImgBox {
    width: 10em;
    height: 10em;
    background-color: green;
    margin: 2rem auto 1rem;
    border-radius: 50%;
    overflow: hidden;
}
.profileImgBox>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
   
}

.sideNavBox {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 35vh;
    border: 2px solid var(--secondaryColor);
    border-bottom: none;
    justify-content: center;
}

.sideNavItem {
    width: 100%;
    height: 25%;
    border-bottom: 2px solid var(--secondaryColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.3vw;
    cursor: pointer;
    color: var(--secondaryColor);
}
:root{
    --primaryTextColor:#505050;
    --secondaryTextColor:#F8F8F8;
    --primaryColor:#EE6C4D;
    --primary2ndColor:#FBDAD2;
    --secondaryColor:#0D61D3;
    --thirdBackground:#ECF2FA;
    --baseColor:#F2EFEF;
    --commonWhite:#fff;
    --buttonColor:#ACACAC;
    --successColor:#25AF46;
    --bidSuccess:#9AF3AC;
    --failureColor:#CF1C1C;
    --box-shadow: 0px 0px 6px #00000029;
    --modal-shadow: 10px 10px 6px #00000029;
    --popBg: #f7ebe9;
}
.page-content {
    width: 80%;
    height: 93vh;
    background-color: var(--commonWhite);
    position: absolute;
    top: 7vh;
    left:20%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.page-content-Login {
    width: 100%;
    left: 0;
    
}

.full-width {
    width: 100%;
    height: fit-content;
    position: static;
}
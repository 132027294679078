.top-navbar {
    width: 100%;
    height: 7vh;
    background-color: var(--primaryColor);
    position: absolute;
    top: 0;
    box-sizing: border-box;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    align-items: center;
}
.top-navbar>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerImg{
    width: 4.5%;
}
.headerImg>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logoutIcon{
   font-size: 2vw;
   color: var(--commonWhite);
   cursor: pointer;
}
.loadTable {
    width: 100%;
    height: 90%;
    padding: .5rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0;
}

.tableHead {
    width: 100%;
    background-color: var(--secondaryColor);
    align-items: center;
    display: flex;
    height: 6%;
    justify-content: space-between;
}

.tableHead>td {
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--commonWhite);
    font-weight: bold;
    font-size: 1vw;

}

.tableBody {
    background-color: var(--primary2ndColor);

}

.tableBody>td {
    font-weight: 500 !important;
    color: var(--primaryTextColor);
    font-size: 1vw;

}

.sno {
    width: 7%;
    justify-content: flex-start !important;
    padding: 0 1rem;
}

.tdate {
    width: 14%;
}

.companyName {
    width: 20%;
    overflow: hidden;
}

.uniqueId {
    width: 10%;
}

.originState {
    width: 31%;
    overflow: hidden;
}

.tstatus {
    width: 10%;
}

.tview {
    width: 7%;
    justify-content: flex-end !important;
    padding: 0 1rem 0 0;
    cursor: pointer;


}

.tviewbody {
    color: var(--primaryColor) !important;
}

.pageCounts {
    width: 100%;
    height: 5%;
    background: var(--commonWhite);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondaryColor);
    font-size: 1vw;
    position: absolute;
    bottom: 0;
    font-weight: bold;
}

.firstPage {
    color: var(--secondaryColor);
    font-size: 1.3vw;
    font-weight: bold;
    cursor: pointer;
}

.nextprevPage {
    color: var(--primaryColor);
    font-size: 1.3vw;
    font-weight: bold;
    margin: 0 .5rem;
    cursor: pointer;
}

.pageNumber {
    padding: 0.1vw .5vw;
    cursor: pointer;
}

.currentPage {
    background: var(--primaryColor);
    color: var(--commonWhite);
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--primaryColor);
    /* Semi-transparent black */
    z-index: 1;
    /* Ensure the backdrop is above other content */
    opacity: .3;
}
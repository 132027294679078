@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
}

/* 100vh class */
.fullheight {
  height: 100vh;
  min-width: fit-content;
}

/* Container */
.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.6rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3vw;
}

h4 {
  font-size: 1rem;
}

p {
  font-size: 1vw;
}

.primaryTextColor {
  color: #196EA4;
}

.secondaryTextColor {
  color: #727171;
}
.PAGINATION_Container {
  height: 6vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  position: fixed;
  bottom: 0;
}
.button_division {
  /* height: 3vw;
  width: 3vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4vw;
  cursor: pointer;
  color: var(--primaryColor);
  font-weight: bold;
}
.button_division_navigate {
  color: var(--secondaryColor);
  cursor: pointer;
  font-size: 1.4vw;
}
.button_division_Page_number {
  height: 1.5vw;
  width: 1.5vw;
  font-size: 1vw;
  cursor: pointer;
  font-weight: 500;
  background-color: var(--primaryColor);
  color: var(--commonWhite);
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
}
.ChangeRoute_main_conainer {
  height: 100vh;
  width: 100%;
  padding: 1%;
}
.ChangeRoute_searchBox {
  height: 8vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}
.ChangeRoute_searchBox input {
  width: 50%;
  height: 70%;
  border-radius: 1.5rem;
  margin: 0;
  padding: 0 1%;
  background-color: var(--thirdBackground);
  border: none;
  color: var(--primaryTextColor);
  font-size: 1vw;
  outline: none;
}
.ChangeRoute_searchBox button {
  height: 70%;
  width: 20%;
  border: none;
  outline: none;
  padding: 0.2rem 0.4rem;
  font-size: 1vw;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--primary2ndColor);
  color: var(--primaryTextColor);
  margin: 0;
}
.ChangeRoute_details {
  width: 100%;
}
.ChangeRoute_data {
  display: flex;
  flex-direction: column;
}
.ChangeRoute_data p {
  font-size: 1.2vw;
  margin-top: 1%;
}
.ChangeRoute_data p span {
  font-weight: bold;
  color: var(--secondaryColor);
}

.ChangeRoute_lorry_details {
  width: 100%;
}

.ChangeRoute_lorry_details p {
  font-size: 1.2vw;
  margin-top: 1vh;
}
.ChangeRoute_lorry_details p span {
  font-weight: bold;
  color: var(--secondaryColor);
}

.ChangeRoute_lorry_working_details {
  width: 100%;
}

.ChangeRoute_lorry_working_details p {
  font-size: 1.2vw;
  margin-top: 1vh;
}
.ChangeRoute_lorry_working_details p span {
  color: var(--secondaryColor);
  font-weight: bold;
}

.ChangeRoute_lorry_details_line_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1% 0;
}
.ChangeRoute_lorry_details_line_box p {
  font-size: 1.2vw;
  font-weight: bold;
  color: var(--primaryTextColor);
}
.ChangeRoute_lorry_details_line_box1 {
  width: 42%;
  height: 2px;
  background-color: var(--primaryTextColor);
}
.ChangeRoute_lorry_details_line_box2 {
  width: 44%;
  height: 2px;
  background-color: var(--primaryTextColor);
}

.changeButton {
  background-color: var(--secondaryColor);
  color: var(--secondaryTextColor);
  border: none;
  outline: none;
  width: 13%;
  padding: 0.8rem 0.5rem;
  font-size: 1.2vw;
  position: fixed;
  right: 3%;
  bottom: 3%;
}
.Changeroute_scroll_box {
  height: 81vh;
  overflow-y: scroll;
}
.Changeroute_scroll_box::-webkit-scrollbar {
  display: none;
}

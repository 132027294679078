.userPopUp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary2ndColor);
  height: 79vh;
  width: 46vw;
}
.confirmPopUp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary2ndColor);
  height: 30vh;
  width: 46vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 10%;
  color: var(--primaryColor);
}
.confirmPopUpbutton {
  width: 100%;
  display: flex;
  column-gap: 10%;
  align-items: center;
  justify-content: center;
}

.confirmPopUpbuttonChild {
  height: 50%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userPopUpCross {
  width: 100%;
  text-align: end;
  padding: 0.5% 1% 0 0;
}
.userPopUpCross p {
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 700;
}

.imageDiv {
  height: 90%;
  margin: 1%;
  overflow: auto;
  cursor: pointer;
  object-fit: cover;
}

.imageDiv img {
  height: 18vw;
  /* height: fit-content; */
  width: 100%;
}

.ViewApplicationPopUp_container {
  height: 15vw;
  width: 30vw;
  background-color: var(--popBg);
  position: fixed;
  left: 60%;
  top: 50%;
  display: flex;
  align-items: start;

  font-weight: 600;
  /* padding: 1vw 2vw; */

  justify-content: start;
  gap: 0vw;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
.cross_view_icon {
  height: 3vw;
  background-color: var(--primaryTextColor);
  /* width: 100%; */
}
.details_user_view {
  display: flex;
  width: 100%;

  align-items: start;
  justify-content: space-between;
  gap: 0.8vw;
  padding: 1vw 1vw;
}
.details_user_view_data {
  display: flex;
  width: 100%;
  font-size: 1.1vw;

  padding: 1vw 0vw;
  color: var(--primaryTextColor);
  align-items: start;
  justify-content: start;
  gap: 0.2vw;
  flex-direction: column;
}
.viewImg_div {
  height: 7vw;
  width: 25%;

  display: flex;
  align-items: start;
  justify-content: start;
}
.ImageProfileView {
  height: 7vw;
  width: 7vw;
  object-fit: cover;
  border: none;
  /* background: red; */
  border-radius: 50%;
}
.cross_view_icon {
  display: flex;
  height: 2vw;
  width: 100%;
  padding: 0 0.3vw;
  font-weight: bold;
  align-items: center;
  justify-content: end;
}
.rxcross2_icon {
  font-size: 1.6vw;
  cursor: pointer;
  color: var(--commonWhite);
}
